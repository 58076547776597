import axios from 'axios';

export const redirectToConversation = function (id) {
  window.CHATWOOT_ALERT(`Redirecting to conversation: ${id}`);

  const url = window.parent.location.pathname;

  const target = url.split('/').slice(0, -1).join('/') + `/${id}`;

  window.VUE_ROUTER.push(target);
};

export function getCookie(cname) {
  let name = cname + '=';
  let ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

export function getHeaders(session) {
  const UNSAFE_HEADERS = [
    'connection',
    'date',
    'transfer-encoding',
    'content-length',
  ];

  const headers = {};

  Object.keys(session).forEach(key => {
    if (UNSAFE_HEADERS.includes(key.toLowerCase())) return;
    headers[key] = session[key];
  });

  return headers;
}

function getEnvironment() {
  switch (window.location.host) {
    case 'ce.farmako.dev': {
      return 'development';
    }
    case 'ce.farmako.tech': {
      return 'staging';
    }
    case 'ce.internal.farmako.app': {
      return 'production';
    }
    default: {
      return 'development';
    }
  }
}

function getApiServerUrl() {
  switch (getEnvironment()) {
    case 'development': {
      return 'https://api.farmako.dev';
    }
    case 'staging': {
      return 'https://api.staging.farmako.app';
    }
    case 'production': {
      return 'https://api.farmako.app';
    }
    default: {
      return 'https://api.farmako.dev';
    }
  }
}

export class ApiClient {
  #order_id = '';

  constructor() {
    this.api = axios.create({
      baseURL: getApiServerUrl(),
      headers: {
        'content-type': 'application/json',
      },
    });

    const sess = decodeURIComponent(getCookie('cw_d_session_info'));

    const headers = getHeaders(sess ? JSON.parse(sess) : {});

    this.chatwoot = axios.create({
      baseURL: window.location.origin + `/api/v1`,
      headers: {
        ...headers,
        'content-type': 'application/json',
      },
      withCredentials: true,
    });
  }

  get order_id() {
    return this.#order_id;
  }

  set order_id(id) {
    this.#order_id = id;
  }

  async sendChatwootMessage({
    content,
    is_private = false,
    conversation_id,
    account_id,
  }) {
    const body = {
      content,
      message_type: 'outgoing',
      private: is_private,
    };

    const res = await this.chatwoot.post(
      `/accounts/${account_id}/conversations/${conversation_id}/messages`,
      body
    );

    return res.data;
  }

  async searchContact(query) {
    const account_id = 1;

    const res = await this.chatwoot.get(
      `/accounts/${account_id}/contacts/search?q=${query}`
    );

    return res.data;
  }

  async evital_request(url, options) {
    const res = await this.api(`/app/medicine/evital${url}`, {
      ...options,
      headers: {
        ...(options?.headers || {}),
        'content-type': 'application/json',
        order_id: this.order_id,
      },
    });

    return res.data;
  }

  async getCustomAttributes(conversation_id = this.order_id) {
    const res = await this.api.get(
      `/app/medicine/order/getAttributes?order_id=${conversation_id}`
    );

    if (res.status !== 200) {
      throw new Error(`Unable to fetch conversation details`);
    }

    return res.data;
  }

  async updateCustomAttributes(attributes, conversation_id = this.order_id) {
    attributes.order_id = conversation_id;

    const res = await this.api.post(
      `/app/medicine/order/setAttributes`,
      attributes
    );

    if (res.status !== 200) {
      throw new Error('Unable to set custom attributes');
    }

    return res.data;
  }

  async searchMedicines(query) {
    try {
      const res = await this.api(`/app/public/internal/ce/medicine/search`, {
        method: 'POST',
        data: {
          searchstring: query,
        },
        headers: {
          order_id: this.order_id,
        },
      });

      return res.data.data.result.map(r => {
        r.stock = r.strip_quantity;
        return r;
      });
    } catch (e) {
      console.error(e);
      return [];
    }
  }

  async searchAlternate(salt) {
    try {
      const res = await this.api(`/app/public/internal/ce/medicine/search`, {
        method: 'POST',
        data: {
          searchstring: salt,
        },
        headers: {
          order_id: this.order_id,
        },
      });

      return res.data.data.result.map(r => {
        r.stock = r.strip_quantity;
        return r;
      });
    } catch (e) {
      console.error(e);
      return [];
    }
  }

  async addToShortbook(data) {
    try {
      if (!data.item.medicine_id || !data.quantity || !data.conversation_id)
        return [];

      const res = await this.api(
        `/app/public/internal/ce/medicine/${encodeURIComponent(
          data.item.medicine_id
        )}/short-book`,
        {
          method: 'POST',
          data: {
            demanded_stock: data.quantity,
          },
          headers: {
            order_id: this.order_id,
          },
        }
      );
      return res.data;
    } catch (e) {
      console.error(e);
      return [];
    }
  }

  async getAgentDetails() {
    const url = `${window.location.origin}/auth/validate_token`;

    const session = JSON.parse(
      decodeURIComponent(getCookie('cw_d_session_info'))
    );

    const headers = getHeaders(session);

    const res = await axios.get(url, {
      withCredentials: true,
      headers,
    });

    return res.data;
  }

  async placeEvitalOrder(conversation_id, account_id) {
    const details = (await this.getAgentDetails()).payload.data;

    const agent = {
      id: details.id,
      email: details.email,
      name: details.name,
      displayName: details.displayName,
    };

    const result = await Promise.all([
      this.sendChatwootMessage({
        content: `FMK_SEND_DRAFT`,
        is_private: true,
        conversation_id,
        account_id,
      }),
    ]);

    return result;
  }

  async sendMedicineDetail(slug) {
    const url = `https://farmako.in/medicine/${slug}`;

    const content = `We have the same medicine with the exact salt composition but a different brand. Please confirm if it works for you.\nHere's the available medicine: ${url}`;

    const agent = (await this.getAgentDetails()).payload.data;

    await this.sendChatwootMessage({
      content,
      is_private: false,
      conversation_id: this.order_id,
      account_id: agent.account_id,
    });
  }

  async savePrescription(
    conversation_id,
    { conditions, name, gender, age, medicines }
  ) {
    const agent = (await this.getAgentDetails()).payload.data;

    const body = {
      cart: medicines,
      conditions,
      name,
      gender,
      age: age,
      doctor_id: agent.id,
    };

    const res = await this.api.post(
      `/app/public/internal/ce/prescription`,
      body,
      {
        headers: {
          order_id: conversation_id.toString(),
        },
      }
    );

    await this.sendChatwootMessage({
      content: `FMK_SEND_DRAFT`,
      is_private: true,
      conversation_id,
      account_id: agent.account_id,
    });

    return res.data;
  }

  async getOrdersFromPhone(phone) {
    const result = await this.api.get(
      `/app/public/internal/ce/user/91${phone}/orders`
    );

    return result.data;
  }

  async duplicateOrderVirtualRx({ reason, conversation_id }) {
    const agentDetails = (await this.getAgentDetails()).payload.data;

    const body = {
      order_id: conversation_id,
      message: reason,
      agent_id: agentDetails.id,
    };

    const { data, statusText } = await this.api.post(
      `/app/medicine/order/modifyBill`,
      body
    );

    if (!data.conversation_id) {
      throw new Error(statusText);
    }

    return data;
  }

  async duplicateOrder(conversation_id = this.order_id) {
    const body = {
      order_id: conversation_id,
    };

    const { data, statusText } = await this.api.post(
      `/app/medicine/order/duplicate`,
      body
    );

    if (!data.conversation_id) {
      throw new Error(statusText);
    }

    return data;
  }

  async cancelOrder({ reason, conversation_id }) {
    const agentDetails = (await this.getAgentDetails()).payload.data;

    const body = {
      message: reason,
      agent_id: agentDetails.id,
    };

    const res = await this.api.post(
      `/app/public/internal/ce/order/${conversation_id}/cancel`,
      body
    );

    if (!res.data.conversation_id) {
      throw new Error(res.statusText);
    }

    return res.data;
  }

  async getMedicineBatches(medicine_id, name) {
    const body = {
      medicine_id,
    };

    if (name) body.batch_name = name;

    try {
      const res = await this.api(`/app/public/internal/ce/medicine/batch`, {
        method: 'POST',
        data: body,
        headers: {
          order_id: this.order_id,
        },
      });

      return res.data.data.map(item => ({
        ...item,
        expired: Date.now() > new Date(item.expiry),
      }));
    } catch (e) {
      console.error(e);
      return [];
    }
  }

  async updateCartItems({ items, conversation_id }) {
    const res = await this.api.patch(
      `/app/public/internal/ce/order/${conversation_id}`,
      items
    );

    return res.data;
  }

  async getCart(conversation_id) {
    const res = await this.api.get(
      `/app/public/internal/ce/order/${conversation_id}`
    );

    return res.data.filter(i => i.item_type === 'inventory');
  }

  async placeCall({ order_id, receiver_type, caller_phone, caller_type }) {
    const headers = {
      'Content-Type': 'application/json',
    };

    const body = {
      order_id,
      receiver_type,
      caller_phone,
      caller_type,
    };

    const res = await this.api.post(
      `/app/public/internal/ce/telephony/initiate-call`,
      body,
      {
        headers,
      }
    );

    return res.data;
  }
}

export const client = new ApiClient({
  doc_server_url: getApiServerUrl(),
  chatwoot_base_url: `https://${window.location.host}/api/v1`,
});
